<template>
  <main class="page_bg_grey blank-aside-js min-h-100vh w-100" :class = "(isOpenAside=='true')?'blank-aside':''">
    <SupplierDetailPage
      product_router="GameProduct"
      page_title="Game"
    ></SupplierDetailPage>
  </main>
</template>
  
<script>
  import SupplierDetailPage from '@/components/endUser/suppliersPages/SupplierDetailPage.vue';
  
  export default {
    name: 'GameSupplier',
    components: {
      SupplierDetailPage
    },
  }
</script>