<template>
  <modal
    :width="760"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalRamiActivate"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			Rami Levi {{ $t("Activate New Number") }}
		</div>
    <div class="px-10 py-8 fs14" style="min-height: 250px;">
      <v-row no-gutters  class="mb-8">
        <v-col
          cols="12"
          sm="4"
        >
          {{ $t("Sim Number") }}:
        </v-col>
        <v-col
          cols="12"
          sm="8"
        >
          <div class="d-flex">
            <input type="text" class="number-input input" :value="prefix_sim_number" disabled>
            <div class="mx-8">
              <input type="text" class="number-input input" maxlength="10" @keypress="isNumber($event)" v-model="SIMNumber">
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
          </div>
          <v-checkbox v-model="isBlockAnohterBranch" :label='$t("Blocking Charges at another branch")' color="primary" class="mt-5"
            hide-details></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-content-end py-6 px-10 border-top">
      <v-btn
        class="text-none mx-10"
        @click="closeModal()"
      >
        {{ $t("Cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        @click="submit"
      >
        {{ $t("Save") }}
      </v-btn>
    </div>
  </modal>	
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: "ModalRamiActivate",
  mixins: [validationMixin],
  data() {
    return {
      prefix_sim_number: "899725000",
      SIMNumber: '',
      isBlockAnohterBranch: false
    }
  },
  validations: {
    SIMNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    }
  },
  methods: {
    ...mapActions('supplier', {
      activeSIMRamiLevi: 'activeSIMRamiLevi',
    }),
    closeModal() {
      this.$modal.hide("ModalRamiActivate");
      this.SIMNumber = "";
      this.isBlockAnohterBranch = false;
    },
    openModal() {
      this.$modal.show("ModalRamiActivate");
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.activeSIMRamiLevi({
        SIMNumber: this.prefix_sim_number + this.SIMNumber,
        BlockOtherBranch: this.isBlockAnohterBranch
      });
      // this.closeModal();
    }
  }
}
</script>

<style>
.number-input {
  width: 130px;
  letter-spacing: 2px;
}
</style>