<template>
  <modal
    :width="760"
    :adaptive="true"
    class="CEModal modal-scroll-bar"
    name="ModalRamiPorting"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
			Rami Levi {{ $t("Number Porting") }}
		</div>
    <div class="border-bottom">
      <v-tabs
        v-model="tab"
        class="header-tabs"
      >
        <v-tab
          class="m-0 font-weight-bold text-none"
          small
        >
          {{ $t("Porting - With Temp Number") }}
        </v-tab>
        <v-tab
          class="m-0 font-weight-bold text-none"
          small
        >
          {{ $t("Porting - No Temp Number") }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items class="px-10 py-8 fs14" v-model="tab">
      <v-tab-item style="min-height: 250px;">
        <v-row no-gutters class="mb-4">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Mobile Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="d-flex flex-wrap"
          >
            <div class="mb-4">
              <input type="text" class="number-input input" @keypress="isNumber($event)" v-model="MobileNumber">
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.MobileNumber.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
            <v-btn
              elevation="1"
              color="primary"
              class="mx-4 mb-4 text-none"
              @click="handleSendVerifyCode"
            >
              {{ $t("Send SMS Verification Code") }}
          </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Temp Mobile Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="d-flex"
          >
            <vue-select
              :options="tempMobileNumber.options"
              class="input cus-select number-select"
              v-model="tempMobileNumber.selected"
              @input="handleChangeTempMobile"
            ></vue-select>
          </v-col>
        </v-row>
        <v-row no-gutters  class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Sim Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <div class="d-flex justify-content-end mb-5 content-ltr">
              <input type="text" class="number-input input" :value="prefix_sim_number" disabled>
              <div class="mx-8">
                <!-- <vue-select
                  :options="tempSimNumber.options"
                  class="input cus-select number-select"
                  v-model="tempSimNumber.selected"
                  @input="handleChangeTempSim"
                ></vue-select> -->
                <input type="text" class="number-input input" maxlength="10" @keypress="isNumber($event)" v-model="SIMNumber">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
            </div>
            <v-checkbox v-model="isBlockAnohterBranch" :label='$t("Blocking Charges at another branch")' color="primary" class="mt-0"
              hide-details></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Verification code") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <input type="text" class="number-input input" @keypress="isNumber($event)" v-model="VerificationCode">
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.VerificationCode.$error">
              {{ $t('Invalid value') }}
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item style="min-height: 250px;">
        <v-row no-gutters class="mb-4">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Mobile Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="d-flex flex-wrap"
          >
            <div class="mb-4">
              <input type="text" class="number-input input" @keypress="isNumber($event)" v-model="MobileNumber">
              <div class="color-red1 fs12 valide-error-msg" v-if="$v.MobileNumber.$error">
                {{ $t('Invalid value') }}
              </div>
            </div>
            <v-btn
              elevation="1"
              color="primary"
              class="mx-4 mb-4 text-none"
            >
              {{ $t("Send SMS Verification Code") }}
          </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters  class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Sim Number") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <div class="d-flex mb-5">
              <input type="text" class="number-input input" :value="prefix_sim_number" disabled>
              <div class="mx-8">
                <input type="text" class="number-input input" maxlength="10" @keypress="isNumber($event)" v-model="SIMNumber">
                <div class="color-red1 fs12 valide-error-msg" v-if="$v.SIMNumber.$error">
                  {{ $t('Invalid value') }}
                </div>
              </div>
            </div>
            <v-checkbox v-model="isBlockAnohterBranch" :label='$t("Blocking Charges at another branch")' color="primary" class="mt-0"
                hide-details></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t("Verification Code") }}:
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <input type="text" class="number-input input" @keypress="isNumber($event)" v-model="VerificationCode">
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.VerificationCode.$error">
              {{ $t('Invalid value') }}
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <div class="d-flex justify-content-end py-6 px-10 border-top">
      <v-btn
        class="text-none mx-10"
        @click="closeModal()"
      >
        {{ $t("Cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        @click="submit"
        :disabled="tab == 0 && !Object.keys(tempMobileNumber.selected).length"
      >
        {{ $t("Save") }}
      </v-btn>
    </div>
  </modal>	
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: "ModalRamiPorting",
  mixins: [validationMixin],
  data() {
    return {
      tab: null,
      prefix_sim_number: "899725000",
      SIMNumber: '',
      isBlockAnohterBranch: false,
      MobileNumber: '',
      VerificationCode:'',
      tempMobileNumber: {
        options: [],
        selected: {}
      },
      // tempSimNumber: {
      //   options: [],
      //   selected: {}
      // },
    }
  },
  computed: {
    ...mapState({
      active_sims_data: (state) => state.supplier.active_sims_data,
    })
  },
  async created() {
    await this.getActiveSims();
    this.tempMobileNumber = {
      options: [],
      selected: ""
    };
    // this.tempSimNumber = {
    //   options: [],
    //   selected: ""
    // };
    if (this.active_sims_data.length) {
      this.active_sims_data.forEach(el => {
        const tempMobile = {
          label: el.mobile_number,
          code: el.mobile_number
        }
        // const tempSim = {
        //   label: el.sim_number,
        //   code: el.mobile_number
        // }
        this.tempMobileNumber.options.push(tempMobile);
        // this.tempSimNumber.options.push(tempSim);
      });
      this.tempMobileNumber.selected = this.tempMobileNumber.options[0];
      // this.tempSimNumber.selected = this.tempSimNumber.options[0];
    }
  },
  validations: {
    SIMNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    MobileNumber: {
      required
    },
    VerificationCode: {
      required
    },

  },
  methods: {
    ...mapActions('supplier', {
      numberPortingRamiLevi: 'numberPortingRamiLevi',
      sendVerifyCodeRamiLevi: 'sendVerifyCodeRamiLevi',
      getActiveSims: 'getActiveSims'
    }),
    closeModal() {
      this.$modal.hide("ModalRamiPorting");
      this.SIMNumber = "";
      this.MobileNumber = "";
      this.VerificationCode = "";
      this.isBlockAnohterBranch = false;
    },
    openModal() {
      this.$modal.show("ModalRamiPorting");
    },
    handleSendVerifyCode() {
      this.$v.MobileNumber.$touch();
      if (this.$v.MobileNumber.$anyError) {
        return;
      }
      this.sendVerifyCodeRamiLevi({
        MobileNumber: this.MobileNumber,
      });
    },
    handleChangeTempMobile(e) {
      // const newVal = this.tempSimNumber.options.find(el => {
      //   return el.label = e.code
      // });
      // this.tempSimNumber.selected = newVal;
    },
    // handleChangeTempSim(e) {
    //   const newVal = this.tempMobileNumber.options.find(el => {
    //     return el.label = e.code
    //   });
    //   this.tempMobileNumber.selected = newVal;
    // },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const tempMobileNumber = '';
      if (this.tab == 0 && Object.keys(this.tempMobileNumber.selected).length) {
        tempMobileNumber = this.tempMobileNumber.selected.code;
      }
      await this.numberPortingRamiLevi({
        SIMNumber: this.prefix_sim_number + this.SIMNumber,
        MobileNumber: this.MobileNumber,
        BlockOtherBranch: this.isBlockAnohterBranch,
        VerificationCode: this.VerificationCode,
        tempMobileNumber: tempMobileNumber
      });
      // this.closeModal();
    }
  }
}
</script>

<style>
.number-input {
  width: 130px;
  letter-spacing: 2px;
}
.number-select {
  width: 160px;
  letter-spacing: 2px;
  padding: 0;
}
</style>