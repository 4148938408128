<template>
  <v-container class="page_container">
    <div v-if="supplierData" class="d-flex align-items-center flex-wrap mt-10 mb-20">
      <h4 class="font-weight-bold">{{ supplierData.name }}</h4>
      <img class="page-main-logo mx-10 object-contain" :src="supplierData.light_logo" alt="">
      <div v-if="supplierData.id == '1101'" class="d-flex flex-wrap m-10">
        <v-btn elevation="1" :style="{ backgroundColor: supplierData.chosen_card_background_color, color: supplierData.product_font_color }"
          class="sim_btn text-none mx-3 mb-5" @click="openModalRamiPorting()">
          <div class="porting-img" :style="{ backgroundColor: supplierData.product_font_color }"></div>
          {{ $t("Number Porting") }}
        </v-btn>
        <v-btn elevation="1" :style="{ backgroundColor: supplierData.chosen_card_background_color, color: supplierData.product_font_color }"
          class="sim_btn text-none mx-3 mb-5" @click="openModalRamiActivate()">
          <div class="activate-img" :style="{ backgroundColor: supplierData.product_font_color }"></div>
          {{ $t("Activate Number") }}
        </v-btn>
      </div>
      <div v-else-if="supplierData.id == '127' || supplierData.id == '201'" class="d-flex flex-wrap m-10">
        <v-btn elevation="1" :style="{ backgroundColor: supplierData.chosen_card_background_color, color: supplierData.product_font_color }"
          class="sim_btn text-none mx-3 mb-5" @click="openModalPartnerIframe()">
          <div class="porting-img" :style="{ backgroundColor: supplierData.product_font_color }"></div>
          {{ $t("Number Porting") }}
        </v-btn>
      </div>
    </div>

    <section class="d-flex flex-wrap mb-10">
      <div v-for="(productCategory) in supplierProductCategories" :key="'pc_' + productCategory.code"
        class="link category__link fs14 mx-5 mb-4 pointer"
        :class="{ 'category__link_active': productCategory.code === currentSupplierProductCategory }"
        @click="currentSupplierProductCategory = productCategory.code"
        v-if="productCategory.data && productCategory.data.length > 0">
        {{ productCategory.label }}
      </div>
    </section>

    <div v-show="currentSupplierProductCategoryItems && currentSupplierProductCategoryItems.length > 0" class="row">
      <div v-for="(product_item) in currentSupplierProductCategoryItems" :key="'pc_pi_' + product_item.id"
        class="col-sm-6 col-md-4 col-lg-3">
        <GameProductCard v-if="['Game', 'Gift card'].includes(page_title)" :product_data="product_item"
          :product_router="product_router" :supplier_data="supplierData" :title="page_title" class="m-5"
          @openInforPopup="handleOpenInforPopup" @changeFavorite="handleChangeFavorite" />
        <CommunicationProductCard v-else-if="page_title == 'Mobile Prepaid'" :product_data="product_item"
          :product_router="product_router" :supplier_data="supplierData" :title="page_title" class="m-5"
          @openInforPopup="handleOpenInforPopup" @changeFavorite="handleChangeFavorite" :isSupplierCategoryItem="false" />
      </div>
    </div>
    <section v-if="notCurrentSupplierProductCategoryItems && notCurrentSupplierProductCategoryItems.length > 0"
      class="mt-25">
      <h5 class="font-weight-bold">{{ $t("Additional products") }}</h5>
      <div class="row">
        <div v-for="(product_item) in notCurrentSupplierProductCategoryItems" :key="'pi_' + product_item.id"
          class="col-sm-6 col-md-4 col-lg-3">
          <GameProductCard v-if="['Game', 'Gift card'].includes(page_title)" :product_data="product_item"
            :product_router="product_router" :supplier_data="supplierData" :title="page_title" class="m-5"
            @changeFavorite="handleChangeFavorite" @openInforPopup="handleOpenInforPopup" />
          <CommunicationProductCard v-else-if="page_title == 'Mobile Prepaid'" :product_data="product_item"
            :product_router="product_router" :supplier_data="supplierData" :title="page_title" class="m-5"
            @openInforPopup="handleOpenInforPopup" @changeFavorite="handleChangeFavorite"
            :isSupplierCategoryItem="true" />
        </div>
      </div>
    </section>
    <ModalRamiPorting ref="modalRamiPortingRef"></ModalRamiPorting>
    <ModalRamiActivate ref="modalRamiActivateRef"></ModalRamiActivate>
    <ModalPartnerIframe ref="modalPartnerIframeRef"></ModalPartnerIframe>
    <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '@/appConfig'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import CategoryShare from '@/components/communication/CategoryShare.vue'
import InforPopup from '@/components/communication/InforPopup.vue'
import GameProductCard from '@/components/endUser/game/GameProductCard.vue'
import CommunicationProductCard from '@/components/endUser/communication/CommunicationProductCard.vue'
import ModalRamiPorting from "@/components/endUser/suppliersPages/ModalRamiPorting.vue";
import ModalRamiActivate from "@/components/endUser/suppliersPages/ModalRamiActivate.vue";
import ModalPartnerIframe from "@/components/endUser/suppliersPages/ModalPartnerIframe.vue";

export default {
  name: 'SupplierDetailPage',
  components: {
    Loading,
    CategoryShare,
    InforPopup,
    GameProductCard,
    CommunicationProductCard,
    ModalRamiPorting,
    ModalRamiActivate,
    ModalPartnerIframe
  },
  props: {
    product_router: {
      type: String,
      required: true
    },
    page_title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      currentSupplierProductCategory: null,
      modal_key: +new Date(),
      inforData: ""
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.supplier.loading,
      supplier_items: state => state.supplier.supplier_items,
      suppliers: state => state.supplier.suppliers,
      suppliers_products: state => state.supplier.suppliers_products,
      product_tags: (state) => state.supplier.product_tags,
    }),
    supplierId() {
      return this.$route.params.id
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] }
      }

      let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
      return supplierData ? supplierData : null
    },
    supplierProductsAll() {
      let supplierProducts = []
      if (this.suppliers_products && this.supplierId in this.suppliers_products) {
        let supplerProductsByType = this.suppliers_products[this.supplierId]

        if (supplerProductsByType) {
          for (let supplier_product_type in supplerProductsByType) {
            for (let supplier_product of supplerProductsByType[supplier_product_type]) {
              supplierProducts.push(supplier_product)
            }
          }
        }
      }

      return supplierProducts ? supplierProducts : []
    },
    supplierProductCategories() {
      let supplierProductCategories = [];
      let favoriteCategoryCode = 'favorite';
      let favoriteSupplierProducts = this.filterSupplierProducts(favoriteCategoryCode, true);
      if (favoriteSupplierProducts && favoriteSupplierProducts.length > 0) {
        supplierProductCategories.push({
          label: this.$t('Favorites'),
          code: favoriteCategoryCode,
          data: favoriteSupplierProducts
        });

        this.setCurrentSupplierProductCategoryIfEmpty(favoriteCategoryCode);
      } else {
        this.unsetCurrentSupplierProductCategoryIfEqual(favoriteCategoryCode);
      }

      for (let tag_index in this.product_tags) {
        let tag = this.product_tags[tag_index];
        let filteredProductsByTag = this.filterSupplierProducts('tag', tag.id);

        if (filteredProductsByTag && filteredProductsByTag.length > 0) {
          supplierProductCategories.push({
            label: tag.name, // add languages on the backend or translations
            code: tag.id,
            data: filteredProductsByTag
          });

          this.setCurrentSupplierProductCategoryIfEmpty(tag.id);
        } else {
          this.unsetCurrentSupplierProductCategoryIfEqual(tag.id);
        }
      }

      return supplierProductCategories;
    },
    currentSupplierProductCategoryItems() {
      let selectedProductsCategoryItems = [];
      if (this.currentSupplierProductCategory
        && this.supplierProductCategories
        && this.supplierProductCategories.length > 0) {
        let selectedProductsCategory = this.supplierProductCategories.find(spc => spc.code === this.currentSupplierProductCategory);

        if (selectedProductsCategory) {
          selectedProductsCategoryItems = selectedProductsCategory.data || [];
        }
      }

      return selectedProductsCategoryItems;
    },
    notCurrentSupplierProductCategoryItems() {
      let notCurrentSupplierProductCategoryItems = [];

      if (this.supplierProductsAll && this.currentSupplierProductCategoryItems) {
        let currentSupplierProductCategoryItemsIds = this.currentSupplierProductCategoryItems.map(p => p.id);
        notCurrentSupplierProductCategoryItems = this.supplierProductsAll.filter(
          sp => !currentSupplierProductCategoryItemsIds.includes(sp.id)
        );
      }

      return notCurrentSupplierProductCategoryItems;
    },
  },
  created() {
    this.getSupplier({ 'supplier_id': this.supplierId })
    this.getSupplierProducts({ 'supplier_id': this.supplierId })
    this.getProductTags()
  },
  methods: {
    ...mapActions('supplier', {
      getSupplier: 'getSupplier',
      getSupplierProducts: 'getSupplierProducts',
      getProductTags: 'getProductTags',
      setFavoriteProduct: 'setFavoriteProduct'
    }),
    openModalRamiPorting() {
      this.$refs.modalRamiPortingRef.openModal();
    },
    openModalPartnerIframe() {
      this.$refs.modalPartnerIframeRef.openModal();
    },
    openModalRamiActivate() {
      this.$refs.modalRamiActivateRef.openModal();
    },
    getApiURL() {
      return appConfig.apiUrl
    },
    setCurrentSupplierProductCategoryIfEmpty(category) {
      this.currentSupplierProductCategory = this.currentSupplierProductCategory || category;
    },
    unsetCurrentSupplierProductCategoryIfEqual(category) {
      this.currentSupplierProductCategory = this.currentSupplierProductCategory === category
        ? null : this.currentSupplierProductCategory;
    },
    filterSupplierProducts(field, value) {
      let filteredSupplierProducts = [];
      if (!value || !field || !this.supplierProductsAll) {
        return filteredSupplierProducts;
      }

      if (field === 'tag') {
        filteredSupplierProducts = this.supplierProductsAll.filter(
          (product) =>
            product.hasOwnProperty('tags') &&
            product.tags.length &&
            product.tags.includes(value),
        );
      } else {
        filteredSupplierProducts = this.supplierProductsAll.filter(
          (product) => product.hasOwnProperty(field) && product[field] == value,
        );
      }

      return filteredSupplierProducts;
    },
    handleChangeFavorite({ id, value }) {
      this.setFavoriteProduct({ product_id: id, supplier_id: this.supplierId, favorite: value });
    },
    /* <!-- ------------information popup-------------- --> */
    handleOpenInforPopup(item) {
      if (item.additional_details == "" || !item.additional_details) {
        this.inforData = item.additional_details_default;
      } else {
        this.inforData = item.additional_details;
      }
      this.$modal.show("inforPopup_" + this.modal_key);
    },
  },
}
</script>

<style scoped lang="scss">
.category__link {
  background-color: #eee;
  border-color: #eee;
}

.sim_btn {
  padding: 0 8px !important;

  img {
    padding: 0 8px;
    height: 24px;
  }
}

.porting-img {
  margin: 0 4px;
  width: 48px;
  height: 24px;
  mask-size: contain !important;
  -webkit-mask: url('/assets/img/icons/numberPorting.svg') no-repeat center;
  mask: url('/assets/img/icons/numberPorting.svg') no-repeat center;
}
.activate-img {
  margin: 0 4px;
  width: 24px;
  height: 24px;
  mask-size: contain !important;
  -webkit-mask: url('/assets/img/icons/sim.png') no-repeat center;
  mask: url('/assets/img/icons/sim.png') no-repeat center;
}
</style>
