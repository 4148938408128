

<template>
  <modal :width="650" :adaptive="true" class="CEModal modal-scroll-bar ModalPartnerIframe" name="ModalPartnerIframe">
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 grey lighten-3 px-12 py-9">
      {{ $t("Partner Number Porting") }}
    </div>
    <div class="d-flex iframe-section">
      <iframe src="https://www.partner.co.il/prepaidmobility#!/login" frameborder="0"></iframe>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalPartnerIframe",
  methods: {
    closeModal() {
      this.$modal.hide("ModalPartnerIframe");
    },
    openModal() {
      this.$modal.show("ModalPartnerIframe");
    },
  }
}
</script>

<style lang="scss">
.ModalPartnerIframe {
  .vm--modal {
    max-width: 90%;
    height: 640px !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.rtl-type .ModalPartnerIframe .vm--modal {
  left: unset !important;
  transform: translateY(-50%) !important;
}

.iframe-section {
  height: 577px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>